import { useEffect } from 'react';

import Loader from 'components/loader/loader';
import EditSite from 'components/manage-sites/edit-site';
import { countries } from 'country-data';
import ReactMarkdown from 'react-markdown';
import { connect, ConnectedProps } from 'react-redux';
import { formatDateFromISO, formatTimeFromSeconds } from 'services/date-service';
import { useAppDispatch } from 'store';
import { getSiteInfo } from 'store/actions/resource-site-actions';
import { RootState } from 'store/reducers';
import {isSiteManager} from 'store/selectors/manager-selector';
import SiteCapability from './site-capability';
import './site-info.scss';
import SiteManagersInfo from './site-managers-info';
import {Alert} from "@mui/lab";
import {Button} from "@mui/material";
import mailTemplateService, {MailTemplate} from 'services/mail-template-service';
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

const mapState = (state: RootState) => ({
  isFetching: state.infoSiteReducer.isFetching,
  site: state.infoSiteReducer.siteInformation,
  isSiteManager: (siteId: number) => isSiteManager(state, siteId),
  isExpired: (siteId: number) => isExpired(state, siteId),
});

const connector = connect(mapState);

type ReduxProps = ConnectedProps<typeof connector>;

type Props = ReduxProps & {
  siteId: number
};

export const isExpired = (state: RootState, siteId: number): boolean => {
  const site = state.resourcesReducer.siteList.find(s => s.resourceId === siteId);
  return site?.isExpired || false;
};
const SiteInfo = ({
                    siteId, site, isFetching, isSiteManager, isExpired,
                  }: Props) => {
  useEffect(() => {
    dispatch(getSiteInfo(siteId, isExpired(siteId)));
  }, [getSiteInfo, siteId]);

  const dispatch = useAppDispatch();
  const getDescription = htmlDesc => <ReactMarkdown children={htmlDesc} />;

  const getRunLevelLabel = (runLevel: number) => {
    if (runLevel === 0) {
      return 'Site';
    }
    if (runLevel === 2) {
      return 'Project';
    }
    if (runLevel > 2) {
      return 'Sub-project';
    }
    else {
      return 'Not managed';
    }
  }
  
  const mailManagerBody = {
    problem: 'I no longer have access to the site : ' + site?.label,
    url: 'portal url: ' + window.location.href,
    description: 'Is it possible to have my access back on this site ?',
  };

  const siteManagersEmails = site?.siteManagers.map(manager => manager.email).filter(email => email) || [''];

  const sismageTemplateMail: MailTemplate = {
    mailto: siteManagersEmails,
    ccs: [],
    subject: 'Expired site, needs renewal',
    body:  mailTemplateService.createMailBody(mailManagerBody),
  };

  return (
      <div className='site-information-container'>
        {!site && isFetching && (
            <div className='loadingSpinner'>
              <Loader />
            </div>
        )}
        {site && (
            <>
              <div className='section-title-site'>SITE INFORMATION</div>
              <div className='site-container'>
                <div className='top-banner-site__panel'>
                  <span className='top-banner-site__title'>{site.label}</span>
                  {!site.isExpired && isSiteManager(site.id) && <EditSite siteInfo={site} />}
                </div>
                {site.isExpired ? (
                    <>
                    <Alert severity="warning" className='site-detail-block'>
                      <div className='expired-message'>This site has been expired. Contact your site manager if you need your rights to be expanded</div>
                    </Alert>
                    <Button className='menu-button-mail' href={mailTemplateService.createHrefTemplateMail(sismageTemplateMail)}>
                      <EmailOutlinedIcon sx={{'color': 'white'}}/>
                      <span className='menu-button-text-mail'>Send mail to managers</span>
                    </Button>
                      <SiteManagersInfo siteInfo={site}/></>
                ) : (
                    <>
                      <div className='site-detail-block'>
                        <div className='section-items'>

                          <div className='item'>
                            <div className='label-item'>Site creation date</div>
                            <div className='value-item'>{formatDateFromISO(site.createdDate)}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Datacenter country</div>
                            <div className='value-item'>{countries[site.countryCode].name}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>User home</div>
                            <div className='value-item'>{site.vmPersistentHomeDir ? 'Persistent' : 'Ephemeral'}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Session launch level</div>
                            <div className='value-item'>{getRunLevelLabel(site.runLevel)}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Max sessions per user</div>
                            <div className='value-item'>{site.maxSessionPerUser}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Max session lifetime</div>
                            <div className='value-item'>{site.maxVmTtlInDays + ' days'}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Default vm endtime</div>
                            <div className='value-item'>{formatTimeFromSeconds(site.defaultVmShutdownTimeInSeconds) + ' ' + Intl.DateTimeFormat().resolvedOptions().timeZone}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Default vm lifetime</div>
                            <div className='value-item'>{site.defaultVmTtlInDays + ' days'}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Custom install regex</div>
                            <div className='value-item'>{site.sismageAllowedVersionsRegex}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Sudoer status</div>
                            <div className='value-item'>{site.sudoTTLInSeconds === -1 ? 'Disabled' : 'Permitted'}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Launched Vms</div>
                            <div className='value-item'>{site.launchedVms ? site.launchedVms : 0}</div>
                          </div>

                          <div className='item'>
                            <div className='label-item'>Selected Sismage version</div>
                            <div className='value-item'>{site.selectedSismageVersion ? site.selectedSismageVersion : 'latest'}</div>
                          </div>
                        </div>
                      </div>
                      <div className='site-desciption-block'>
                        <div className='site-desciption-block__title'>Description or Comments</div>
                        <div className='site-desciption-block__content'>{getDescription(site.description)}</div>
                      </div>
                      <SiteManagersInfo siteInfo={site} />
                      <SiteCapability siteInfo={site} />
                    </>
                )}
              </div>
            </>
        )}
      </div>
  );
};


export default connector(SiteInfo);
